<template>
  <section>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-space-between flex-wrap">
        <h1>{{ employee.full_name | uppercase }}</h1>

        <span>
          <v-btn
            class="mr-3"
            color="warning"
            :to="{ name: 'employee-form', params: { uuid } }"
          >
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>

          <v-btn
            color="error"
            @click="handleDeleteModal"
          >
            <v-icon left>mdi-delete</v-icon>
            Eliminar
          </v-btn>
        </span>
      </v-col>
    </v-row>

    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <b>Nombre:</b> {{ employee.first_name }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Apellido Paterno:</b> {{ employee.last_name }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Apellido Materno:</b> {{ employee.mother_last_name }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>RFC:</b> {{ employee.rfc }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Correo electrónico:</b> {{ employee.email }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Teléfono de casa:</b> {{ employee.home_phone || '---' }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Celular:</b> {{ employee.mobile_phone }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Fecha de nacimiento:</b> {{ employee.birthday | date }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Observaciones:</b> {{ employee.observations || '---' }}
          </v-col>

          <v-col v-if="isNotCustomer" cols="12" sm="6">
            <b>Rol:</b> {{ employee.user.role | role }}
          </v-col>
        </v-row>

        <v-divider class="my-5"></v-divider>

        <AddressDetail :address="employee.address" />
      </v-container>
    </v-card>

    <Modal
      :show.sync="showDeleteModal"
      @submit="remove"
    >
      <template #title>Eliminar</template>

      <template #body>
        ¿Deseas eliminar al empleado <b>{{ employee.full_name | uppercase }}</b>?
      </template>

      <template #action>Eliminar</template>
    </Modal>
  </section>
</template>

<script>
// Config
import config from '@/config';

// API
import EmployeeAPI from '@/api/employee';

// Components
import AddressDetail from '@/components/address/Detail.vue';

// Mixins
import DetailMixin from '@/mixins/DetailMixin';

export default {
  name: 'EmployeeDetail',

  props: {
    uuid: {
      type: String,
      required: true,
    },

    role: {
      type: String,
      default: null,
    },
  },

  components: {
    AddressDetail,
  },

  mixins: [
    DetailMixin,
  ],

  data: () => ({
    employee: {
      address: {},
      birthday: new Date(),
      user: {},
    },
    listRouteName: 'employee-list',
  }),

  computed: {
    breadcrumbs() {
      const name = this.employee.full_name || '';
      return [
        {
          text: 'Personal',
          disabled: false,
          exact: true,
          to: { name: 'employee-list' },
        },
        {
          text: name.toUpperCase(),
          disabled: true,
        },
      ];
    },

    isNotCustomer() {
      return this.role !== config.roles.CUSTOMER.key;
    },
  },

  async created() {
    this.api = new EmployeeAPI();
    await this.detail();
  },

  methods: {
    async detail() {
      this.$store.commit('showLoader');
      try {
        this.employee = await this.api.detail(this.uuid);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
